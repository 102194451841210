<template>
    <span ref="animatedNumber">0</span>
</template>
<script>
export default defineNuxtComponent({
    props: {
        numberValue: {
            type: Number,
            default: 0,
            required: true,
        },
    },

    data() {
        return {
            observer: null,
        }
    },

    mounted() {
        this.observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.animateValue(entry.target, 0, this.numberValue, 2000)
                    this.observer.unobserve(entry.target)
                }
            })
        })

        this.observer.observe(this.$refs.animatedNumber)
    },

    computed: {
        showDecimals() {
            return this.numberValue.toString().includes('.')
        },
    },

    methods: {
        //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/floor
        decimalAdjust(type, value, exp) {
            type = String(type)
            if (!['round', 'floor', 'ceil'].includes(type)) {
                throw new TypeError(
                    "The type of decimal adjustment must be one of 'round', 'floor', or 'ceil'.",
                )
            }
            exp = Number(exp)
            value = Number(value)
            if (exp % 1 !== 0 || Number.isNaN(value)) {
                return NaN
            } else if (exp === 0) {
                return Math[type](value)
            }
            const [magnitude, exponent = 0] = value.toString().split('e')
            const adjustedValue = Math[type](`${magnitude}e${exponent - exp}`)
            // Shift back
            const [newMagnitude, newExponent = 0] = adjustedValue
                .toString()
                .split('e')
            return Number(`${newMagnitude}e${+newExponent + exp}`)
        },

        // Decimal round
        round10(value, exp) {
            return this.decimalAdjust('round', value, exp)
        },

        animateValue(obj, start, end, duration) {
            let startTimestamp = null

            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp
                const progress = Math.min(
                    (timestamp - startTimestamp) / duration,
                    1,
                )

                if (this.showDecimals) {
                    obj.innerHTML = this.round10(
                        progress * (end - start) + start,
                        -1,
                    )
                } else {
                    obj.innerHTML = Math.floor(
                        progress * (end - start) + start,
                        -1,
                    )
                }

                if (progress < 1) {
                    window.requestAnimationFrame(step)
                }
            }

            window.requestAnimationFrame(step)
        },
    },
})
</script>
